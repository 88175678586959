import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Header from "../Header"
import Footer from "../Footer"
import {
  Container,
  Form,
  FormGroup,
  Input,
} from "reactstrap"
import { Button } from "../Reactstrap_Components/Button"
import "./chatbot.css"
import prettier from "prettier/standalone"
import parserBabel from "prettier/parser-babel"
import ReactMarkdown from "react-markdown"


const Chatbot = ({ authorize }) => {
  const [loadingForGen, setLoadingForGen] = useState(false),
    [feedback, setFeedback] = useState("")

  const handleFeedback = async (isPositive) => {
    await axios({
      url: "https://api.traindex.io/openai/openai-gen/feedback",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
      },
      data: JSON.stringify({
        "intent": isPositive, "chat-history": messages, "userFeedback": feedback
      }),
      timeout: 2000
    })
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      runConversation()
    }
  }
  const [promptInputValue, setpromptInputValue] = useState("")
  const [histMessages, sethistMessages] = useState([
    {
      role: "system", content: `
      Role and Goal: 
      Act as a Patent Attorney with the ability to perform detailed patent analysis and write patent documents. \
Handle queries related to patent invalidation by performing multiple API calls.

      Behavior Instructions:
         a. Get Patent Details:
            - Make an API call to get the details of the specified patent using the provided patent number (if needed).
            - Extract the necessary content/details from the response.
         b. Prior Art Search:
            - Using the content/details from the first API call, make another API call to search for relevant \
patents prior to the provided cut-off date.
            - Ensure the cut-off date is used as a filter for the prior art search.
         c. Analyze and Respond:
            - Analyze the patents obtained from the prior art search to determine their relevance to the \
invalidation request.
            - Provide a comprehensive response based on the analysis.

      Knowledge: A Patent searching API (Traindex) is integrated with you. From there you can request Patents \
information based on user query. If users asks about specific scientific or non scientific idea or concept, \
or prior art, go to Traindex and fetch relevant Patents data to have it in your context.
      
      To fetch results from Traindex, first parameter is message, second is no_of_results and third is priortiy_date
      - message is the concept idea of which we want to fetch relevant patents. DONOT SUMMARIZE THE CONCEPT \
IDEA AT ALL. TRY TO GIVE EXACT WORDING TO API
      - no_of_results enables to fetch N number of relevant patents. By default it will be 5
      - priority_date enables to filter patents whose Priority Date are prior to given date. Filtered patents \
are called Prioir Arts. By default it will be 20240501. MAKE SURE TO LOOK FOR PRIORITY DATES IF USER ASKS FOR

      
      Limitations: You are only supposed to answer queries related to Patents.

      Abilities: Following are your abilities
      - You are able to format UCID/publication number before requesting Traindex API. If any ucid comes in \
without dashes, you are supposed to add them by yourself. If ucid comes like US12345678B1, format it like \
US-12345678-B1 and then route it to API.
      Analyze even after you get response from API and supporting functions
      `
       
    },
    {
      role: "assistant", content: "Hi! I am an assistant to\
  help you with Patent drafting, analysis and much more. How can I help you?"
    }
  ])

  const [messages, setMessages] = useState([{
    text: "Hi! I am an assistant to help with Prior art\
  search and analysis, Patent drafting and much more.", isUser: false
  }])

  const request_traindex_api = async (message,no_of_results,priority_date) => {
    var response = ""
    try{
      response = await axios({
        url: "https://api.traindex.io/openai/openai-gen/processForGPTs",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
        },
        data: JSON.stringify({ "message": message,"no_of_results":no_of_results,"priority_date":priority_date })
      })
    } catch(error){
      return "Sorry aboout that. There is some internal error! Try again later"
    }

    return response.data
  }
  const request_for_ucid_details = async (patent_number) => {
    var response = ""
    try{
      response = await axios({
        url: "https://api.traindex.io/openai/openai-gen/getUCIDtext",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
        },
        data: JSON.stringify({ "patent_number": patent_number })
      })
    }catch(error){
      return "Sorry aboout that. There is some internal error! Try again later"

    }
    
    return response.data
  }
  const request_details_and_search = async (patent_number,priority_date) => {
    var finalResponse = ""
    try{
      const response1 = await axios({
        url: "https://api.traindex.io/openai/openai-gen/getUCIDtext",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
        },
        data: JSON.stringify({ "patent_number": patent_number })
      })
  
      finalResponse = await axios({
        url: "https://api.traindex.io/openai/openai-gen/processForGPTs",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
        },
        data: JSON.stringify({ "message": response1.data.patent_text,"no_of_results":5,"priority_date":priority_date })
      })
    }catch(error){
      return "Sorry aboout that. There is some internal error! Try again later"
    }

    return finalResponse.data
  }

  const patents_searching_api = {
    "type": "function",
    "function": {
      "name": "patents_searching_api",
      "description":"Fetches patents data relevant to a specific search query. Fetched patents are potential Prior Art \
patents based on Search Query",

      "parameters": {
        "type": "object",
        "properties": {
          "message": {
            "type": "string",
            "description": "Make proper constructive query based on what user requires to fetch relevant results.",
          },
          "no_of_results": {
            "type": "string",
            "description": "Number of results to fetch",
          },
          "priority_date": {
            "type": "string",
            "description": "Filters patents by mentioned priority date in YYYYMMDD format. Filtered patents \
are called as Prior Art",
          },
        },
        "required": ["message"],
      },
    },
  }  
  const get_details_patent_number = {
    "type": "function",
    "function": {
      "name": "get_details_patent_number",
      "description":"If user only wants details of UCID / Patent Number, and there is no context in history",

      "parameters": {
        "type": "object",
        "properties": {
          "patent_number": {
            "type": "string",
            "description": "Patent Number/UCID of which details needs to be fetched",
          },
        },
        "required": ["patent_number"],
      },
    },
  } 
  const fetch_UCID_then_patent_search = {
    "type": "function",
    "function": {
      "name": "fetch_UCID_then_patent_search",
      "description":"If user wants to search, invalidate or find prior of a given UCID and there is no content of \
given UCID /  Patent number. Get patent number and priority date if exists",
      "parameters": {
        "type": "object",
        "properties": {
          "patent_number": {
            "type": "string",
            "description": "Patent Number/UCID of which details needs to be fetched",
          },
          "priority_date": {
            "type": "string",
            "description": "Filters patents by mentioned priority date in YYYYMMDD format. Filtered patents \
are called as Prior Art",
          },
        },
        "required": ["patent_number"],
      },
    },
  } 
  const TOOLS = [patents_searching_api,fetch_UCID_then_patent_search,get_details_patent_number,]

  const request_openai_api = async (API_BODY) => {
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
    return fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + OPENAI_API_KEY
      },
      body: JSON.stringify(API_BODY)
    }).then((data) => {
      return data.json()
    })
  }

  const runConversation = async (event) => {
    setLoadingForGen(true)
    const newHistMessages  = []
    var functionResponse = null


    const prompt = promptInputValue
    const newMessages = [...messages, { text: prompt, isUser: true }]
    setMessages(newMessages)

    newHistMessages.push({ "role": "user", "content": prompt })

    setpromptInputValue("")
  
    const API_BODY = {
      model: "gpt-4-turbo",
      messages: [...histMessages,...newHistMessages],
      temperature: 0,
      tools: TOOLS,
      tool_choice: "auto",
    }
    
    try {
      const data = await request_openai_api(API_BODY)
      const responseMessage = data.choices[0].message
      const toolCalls = responseMessage.tool_calls

      if (toolCalls) {
        newHistMessages.push(responseMessage)

        for (const toolCall of toolCalls) {
          const functionName = toolCall.function.name
          const functionArgs = JSON.parse(toolCall.function.arguments)

          if (functionName == "patents_searching_api") {
            functionResponse = await request_traindex_api(functionArgs.message,
              functionArgs.no_of_results,functionArgs.priority_date)
          }
          else if (functionName == "get_details_patent_number")  {
            functionResponse = await request_for_ucid_details(functionArgs.patent_number)
          }
          else if (functionName == "fetch_UCID_then_patent_search")  {
            functionResponse = await request_details_and_search(functionArgs.patent_number,functionArgs.priority_date)
          }
          const jsonString = JSON.stringify(functionResponse, null, 2)

          const formattedResponse = prettier.format(jsonString, {
            parser: "json",
            plugins: [parserBabel],
          })

          newHistMessages.push({ "role": "tool", "content": formattedResponse,"tool_call_id": toolCall.id,  })
          
        }
        const API_BODY_2 = {
          model: "gpt-4o",
          messages: [...histMessages,...newHistMessages],
          temperature: 0,
        }

        const data2 = await request_openai_api(API_BODY_2)
        const botResponse = data2.choices[0].message.content
        const updatedMessages = [...newMessages, { text: botResponse, isUser: false }]
        setMessages(updatedMessages)
      } else {
        const botResponse = responseMessage.content
        const updatedMessages = [...newMessages, { text: botResponse, isUser: false }]
        setMessages(updatedMessages)

        newHistMessages.push({ "role": "assistant", "content": botResponse })
        
      }
    } catch (error) {
      console.error("Error:", error)
    }
    sethistMessages(prevMessages => [...prevMessages, ...newHistMessages])
    setLoadingForGen(false)
  }

  return (
    <div className="justify-content-center px-1">
      {!authorize && <Header dark chatbot />}
      <div className="justify-content-center px-1 mt-5">
        <Container className="mt-4 mx-auto">
          <h3>Ask Traindex?</h3>
          <div>
            <div className="chatbot-container">
              <div className="chatbot-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.isUser ? "user" : "bot"}`}>
                    <div className="symbols">
                      {message.isUser ? <span className="symbol-icon">Y </span> :
                        <span className="symbol-icon">D </span>}
                      {message.isUser ? <span className="symbol-text">You </span> :
                        <span className="symbol-text">Dexi </span>}
                    </div>
                    <div className="message-text">
                      <ReactMarkdown>{message.text}</ReactMarkdown>
                    </div>
                  </div>
                ))}
              </div>
              <div className="chatbot-input">
                {loadingForGen && (
                  <div className="typing-icon-container">
                    <div className="typing-dot"></div>
                    <div className="typing-dot"></div>
                    <div className="typing-dot"></div>
                  </div>
                )}
                <input
                  type="text"
                  value={promptInputValue}
                  onChange={(e) => setpromptInputValue(e.target.value)}
                  placeholder="Type your message..."
                  className="input-field"
                  onKeyDown={handleKeyDown}
                />
                <button onClick={runConversation} className="send-button">Send</button>
              </div>

              {messages && (
                <div style={{ backgroundColor: "#F2F2F2", padding: "15px", margin: "auto", width: "70%" }}>
                  <p style={{ whiteSpace: "pre-line" }}>Please provide with your valuable
                    feedback to help us improve
                  </p>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <p style={{ margin: "0 0 0 0" }}>Helpful?</p>
                    <Button className="thumb" onClick={() => handleFeedback(true)}
                      style={{ backgroundColor: "transparent", border: "none", fontSize: "20px" }} >👍🏽</Button>
                    <Button className="thumb" onClick={() => handleFeedback(false)}
                      style={{
                        marginRight: "10px", backgroundColor: "transparent", border: "none",
                        fontSize: "20px"
                      }}>👎🏽</Button>
                  </div>
                  <Form >
                    <FormGroup style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                      <Input
                        type="text"
                        placeholder="Feedback"
                        value={feedback}
                        onChange={(event) => setFeedback(event.target.value)}
                        style={{ flex: 1 }}
                      />
                      <Button onClick={() => handleFeedback(false)}
                        style={{ marginRight: "10px", marginLeft: "10px" }}>Submit</Button>
                    </FormGroup>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      {!authorize && <Footer home />}
    </div>
  )
}

Chatbot.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Chatbot
